<template>
  <v-footer
    style="position: relative;"
    class="pa-0 d-flex flex-column"
    @click="clicked"
  >
    <FooterSite
      :links="links"
      :links2="links2"
      :applicationConfig="applicationConfig"
      v-if="mainTenantName == 'sait'"
    />
    <FooterShop
      :links="links"
      :links2="links2"
      :applicationConfig="applicationConfig"
      v-else
    />
  </v-footer>
</template>
<style scoped lang="scss">
.v-footer {
  border-bottom: 13px solid $primary !important;
  background-color: var(--v-grey-lighten1);
  &--image {
    position: absolute;
    left: 0;
    right: auto;
    top: -50px;
  }
}
.link-title {
  font-weight: 500;
  font-size: 18px;
}
.container {
  background-color: var(--v-grey-lighten1);
}
.v-expansion-panel-header {
  border-bottom: 1px solid $border-color !important;
}
.logo-gap {
  column-gap: 7px;
}
.footer-copyright {
  font-size: 13px;
  margin-bottom: 40px;
  border-radius: 8px;
}
.nav-col:last-child {
  color: var(--v-black-base) !important;
  // background-color: var(--v-grey-base) !important;
  border-radius: 8px;
  padding-right: 0px;
  padding-left: 8px;
}
// .nav-col {
//   > div:last-child {
//     margin-bottom: 0px;
//     margin-top: auto;
//   }
// }
.footer-expansion {
  ::v-deep .v-expansion-panel-header {
    background-color: var(--v-grey-lighten2) !important;
  }
  ::v-deep .v-expansion-panel {
    background-color: var(--v-grey-lighten2) !important;
  }
  .expansion-title {
    color: var(--v-black-base) !important;
  }
  .v-expansion-panel:last-of-type {
    .expansion-title {
      color: var(--v-black-base) !important;
    }
  }
}
</style>

<script>
import clickHandler from "@/commons/mixins/clickHandler";
import FooterShop from "./FooterShop.vue";
import FooterSite from "./FooterSite.vue";
import { mapState, mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "Footer",
  mixins: [clickHandler],
  components: {
    FooterShop,
    FooterSite
  },
  props: {
    links: { type: Array, required: true },
    links2: { type: Array, required: false },
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      payments: [
        {
          icon: "mastercard-curved-64px",
          name: "Pagamento mastercard",
          width: 47
        },
        {
          icon: "visa-curved-64px",
          name: "Pagamento Visa",
          width: 47
        },
        {
          icon: "paypal-curved-64px",
          name: "Pagamento Paypal",
          width: 47
        },
        {
          icon: "amex-curved-64px",
          name: "Pagamento Amex",
          width: 47
        },
        {
          icon: "satispay",
          name: "Pagamento Satispay",
          width: 97
        }
      ],
      selectedTab: null,
      panel: []
    };
  },
  computed: {
    ...mapState({
      language: ({ app }) => app.language
    }),
    ...mapGetters({
      site: "custom/getSite"
    }),
    version() {
      return global.config.version;
    },
    logoSrc() {
      return `/logo/logo-${this.language || "it"}.svg`;
    },
    logoDesktop() {
      return get(
        this.applicationConfig,
        "metaData.template_model.LOGO_DESKTOP"
      );
    },
    logoMobile() {
      return get(this.applicationConfig, "metaData.template_model.LOGO_MOBILE");
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes?.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    isCreditsClass(classes) {
      return classes.search("classes") > -1;
    },
    openCookieBotWidget() {
      // eslint-disable-next-line no-undef
      Cookiebot.show();
    },
    linkName(child) {
      return child?.name.length > 1 ? child.name : "Vai al sito";
    },
    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".pdf")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } else if (e.target.className.includes("cookie-widget-trigger")) {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          }
        } else {
          this.clicked(e);
        }
      });
    },
    goToExternalPage(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        //if app open on _system browser
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    }
  }
};
</script>
