<template>
  <div class="w-100">
    <img
      class="v-footer--image"
      src="/img_layout/profilo-montagne.svg"
      alt=""
    />
    <v-row no-gutters>
      <v-col cols="12" md="3">
        <v-container fluid>
          <router-link
            :to="{ name: 'Home' }"
            class="logo-link"
            aria-label="Torna alla homepage"
            title="Clicca per andare alla homepage"
          >
            <!-- logo -->
            <img
              id="logo-coop-shop"
              class="logo"
              :src="logoSrc"
              alt="Logo Coop Trentino"
            />
          </router-link>
          <div
            class="font-weight-bold mt-4 mb-3"
            v-html="$t('footer.copyright')"
          ></div>
          <div class="mb-3" v-html="$t('footer.extras')"></div>
          <small
            class="d-block"
            v-html="$t('footer.version', [version])"
          ></small>
        </v-container>
      </v-col>
      <v-col cols="12" md="6">
        <v-container fluid>
          <v-row v-if="isDesktop">
            <v-col v-for="link in links" :key="link.templateNavItemId">
              <span class="d-inline-block text-h4 link-title mt-2 mb-6">{{
                link.name
              }}</span>
              <!-- <pre>{{ link }}</pre> -->
              <v-row>
                <v-col
                  cols="12"
                  v-for="(child, index) in link.templateNavItems"
                  :key="child.templateNavItemId"
                  class="pt-0"
                >
                  <a
                    v-if="child.itemUrl && child.itemUrl !== ''"
                    :aria-label="linkName(child)"
                    :title="linkName(child)"
                    :id="`footer-menu-${child.templateNavItemId}`"
                    :href="child.itemUrl || '#'"
                    class="default--text text-underline"
                    :class="[child.classStyle, { 'no-evt': index == 2 }]"
                    :target="child.target || '_self'"
                    @click="onLinkClick"
                  >
                    {{ child.name }}
                  </a>
                  <span
                    v-else
                    :key="child.templateNavItemId"
                    class="default--text text-decoration-none"
                    :class="child.classStyle"
                    >{{ child.name }}</span
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-expansion-panels
              accordion
              flat
              tile
              :focusable="selectedTab"
              role="presentation"
            >
              <v-expansion-panel
                v-for="link in links"
                :key="link.templateNavItemId"
              >
                <v-expansion-panel-header
                  class="link-title"
                  :aria-label="link.name"
                  :id="`header-${link.templateNavItemId}`"
                  role="button"
                  :aria-expanded="link.expanded ? 'true' : 'false'"
                  :aria-controls="`panel-${link.templateNavItemId}`"
                >
                  {{ link.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  :id="item.templateNavItemId"
                  :aria-labelledby="item.templateNavItemId"
                  role="region"
                  class="py-3"
                  v-for="item in link.templateNavItems"
                  :key="item.templateNavItemId"
                >
                  <a
                    :aria-label="item.name"
                    class="text-decoration-none"
                    :id="item.templateNavItemId"
                    :target="item.target"
                    @click="clicked"
                    :href="item.itemUrl"
                  >
                    <span class="secondary--text" :class="item.classes">{{
                      item.name
                    }}</span>
                  </a>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="ma-0 w-100">
      <v-container fluid class="grey">
        <v-row>
          <v-col cols="12" md="10">
            <span class="d-block text-h5 mb-4 mt-2">{{
              $t("footer.downloadApp.title")
            }}</span>
            <div
              class="d-flex flex-wrap justify-sm-space-between justify-md-start"
            >
              <div class="my-3 mr-md-5 d-flex flex-wrap align-center">
                <img
                  width="60"
                  height="60"
                  class="rounded-md mr-3 mb-3 mb-sm-0"
                  contain
                  alt="logo clickandcoop"
                  src="/img_layout/footer/click_coop.png"
                />
                <div class="d-flex flex-column">
                  <p class="px-1">
                    {{ $t("footer.downloadApp.click&coop.title") }}
                  </p>
                  <div class="d-flex flex-wrap align-center">
                    <a
                      :aria-label="
                        $t('footer.downloadApp.click&coop.link.ios.title')
                      "
                      target="_blank"
                      :href="$t('footer.downloadApp.click&coop.link.ios.link')"
                    >
                      <img
                        :alt="
                          $t('footer.downloadApp.click&coop.link.ios.title')
                        "
                        class="px-1"
                        width="120"
                        height="auto"
                        contain
                        src="/img_layout/footer/app-store.png"
                      />
                    </a>
                    <a
                      :aria-label="
                        $t('footer.downloadApp.click&coop.link.android.title')
                      "
                      target="_blank"
                      :href="
                        $t('footer.downloadApp.click&coop.link.android.link')
                      "
                    >
                      <img
                        :alt="
                          $t('footer.downloadApp.click&coop.link.android.title')
                        "
                        class="px-1"
                        width="120"
                        height="auto"
                        contain
                        src="/img_layout/footer/google-play.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="my-3 d-flex flex-wrap align-center ">
                <img
                  alt="logo incooperazione"
                  width="60"
                  height="60"
                  class="rounded-md mr-3 mb-3 mb-sm-0"
                  contain
                  src="/img_layout/footer/in_coop.png"
                />
                <div class="d-flex flex-column">
                  <p class="px-1">
                    {{ $t("footer.downloadApp.inCooperazione.title") }}e
                  </p>
                  <div class="d-flex flex-wrap align-center">
                    <a
                      :aria-label="
                        $t('footer.downloadApp.inCooperazione.link.ios.title')
                      "
                      target="_blank"
                      :href="
                        $t('footer.downloadApp.inCooperazione.link.ios.link')
                      "
                    >
                      <img
                        :alt="
                          $t('footer.downloadApp.inCooperazione.link.ios.title')
                        "
                        class="px-1"
                        width="120"
                        height="auto"
                        contain
                        src="/img_layout/footer/app-store.png"
                      />
                    </a>
                    <a
                      :aria-label="
                        $t(
                          'footer.downloadApp.inCooperazione.link.android.title'
                        )
                      "
                      target="_blank"
                      :href="
                        $t(
                          'footer.downloadApp.inCooperazione.link.android.link'
                        )
                      "
                    >
                      <img
                        :alt="
                          $t(
                            'footer.downloadApp.inCooperazione.link.android.title'
                          )
                        "
                        class="px-1"
                        width="120"
                        height="auto"
                        contain
                        src="/img_layout/footer/google-play.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="2">
            <span class="d-block text-h5 mb-4 mt-2">{{
              $t("footer.linkedin.title")
            }}</span>
            <a
              target="_blank"
              :aria-label="$t('footer.linkedin.label')"
              :href="$t('footer.linkedin.link')"
            >
              <img
                :alt="$t('footer.linkedin.label')"
                width="45"
                height="45"
                class="rounded-sm my-3"
                src="/img_layout/footer/linkedin.svg"
              />
            </a>
          </v-col>
        </v-row>
        <!-- <v-row v-for="link in links2" :key="link.templateNavItemId" class="">
            <v-container class="grey">
              <h5 class="link-title mb-3">{{ link.name }}</h5>
              <v-row>
                <v-col
                  cols="auto"
                  v-for="child in link.templateNavItems"
                  :key="child.templateNavItemId"
                >
                  <a
                    v-if="child.itemUrl && child.iconSource"
                    :href="child.itemUrl"
                    :class="child?.classStyle"
                  >
                    <img :src="child.iconSource" alt="" />
                  </a>
                </v-col>
              </v-row>
            </v-container>
          </v-row> -->
      </v-container>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.v-footer {
  border-bottom: 13px solid $primary !important;
  background-color: var(--v-grey-lighten1);
  &--image {
    position: absolute;
    left: 0;
    right: auto;
    top: -50px;
  }
  .v-expansion-panel {
    background-color: var(--v-grey-lighten1);
  }
}
.link-title {
  font-weight: 500;
  font-size: 18px;
}
.container {
  background-color: var(--v-grey-lighten1);
}
.v-expansion-panel-header {
  border-bottom: 1px solid $border-color !important;
}
.logo-gap {
  column-gap: 7px;
}
.footer-copyright {
  font-size: 13px;
  margin-bottom: 40px;
  border-radius: 8px;
}
.nav-col:last-child {
  color: var(--v-black-base) !important;
  // background-color: var(--v-grey-base) !important;
  border-radius: 8px;
  padding-right: 0px;
  padding-left: 8px;
}
// .nav-col {
//   > div:last-child {
//     margin-bottom: 0px;
//     margin-top: auto;
//   }
// }
.footer-expansion {
  ::v-deep .v-expansion-panel-header {
    background-color: var(--v-grey-lighten2) !important;
  }
  ::v-deep .v-expansion-panel {
    background-color: var(--v-grey-lighten2) !important;
  }
  .expansion-title {
    color: var(--v-black-base) !important;
  }
  .v-expansion-panel:last-of-type {
    .expansion-title {
      color: var(--v-black-base) !important;
    }
  }
}
</style>

<script>
import clickHandler from "@/commons/mixins/clickHandler";
import get from "lodash/get";
import { mapState } from "vuex";

export default {
  name: "Footer",
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: true },
    links2: { type: Array, required: false },
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      selectedTab: null,
      panel: []
    };
  },
  computed: {
    ...mapState({
      language: ({ app }) => app.language
    }),
    version() {
      return global.config.version;
    },
    logoSrc() {
      return `/logo/logo-${this.language || "it"}.svg`;
    },
    logoDesktop() {
      return get(
        this.applicationConfig,
        "metaData.template_model.LOGO_DESKTOP"
      );
    },
    isDesktop() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    logoMobile() {
      return get(this.applicationConfig, "metaData.template_model.LOGO_MOBILE");
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes?.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    isCreditsClass(classes) {
      return classes.search("classes") > -1;
    },
    openCookieBotWidget() {
      // eslint-disable-next-line no-undef
      Cookiebot.show();
    },
    linkName(child) {
      return child?.name.length > 1 ? child.name : "Vai al sito";
    },
    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".pdf")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } else if (e.target.className.includes("cookie-widget-trigger")) {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          }
        } else {
          this.clicked(e);
        }
      });
    },
    goToExternalPage(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        //if app open on _system browser
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    }
  }
};
</script>
